<template>
  <v-card width="310" rounded="lg">
    <v-toolbar flat>
      <v-toolbar-title class="toolbar-title">
        {{ successAction ? "Sucesso!" : "Solicitar Nova Senha" }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="successAction" class="d-flex flex-column justify-center">
      <v-icon class="icon" color="primary">far fa-check-circle</v-icon>

      <p class="text-center mt-5">E-mail enviado.</p>

      <p class="text-center">Verifique sua caixa de entrada.</p>

      <v-btn outlined color="primary" class="no-text-transform" to="/entrar">
        Voltar para o login
      </v-btn>
    </v-card-text>

    <v-card-text v-else>
      <v-form>
        <v-text-field
          type="text"
          label="E-mail*"
          :autofocus="true"
          autocomplete="off"
          prepend-icon="fas fa-at"
          :disabled="loadButtonSubmit"
          v-model="data.email"
          :error-messages="errors.email"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions v-if="!successAction">
      <v-btn link text color="primary" class="no-text-transform" to="/entrar">
        Lembrei a senha!
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="ma-2 white--text no-text-transform"
        :loading="loadButtonSubmit"
        @click="handleSubmitData()"
      >
        Solicitar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import AuthService from "@/services/Auth/AuthService";

export default {
  name: "RequestNewPassword",

  data: () => ({
    data: {},
    errors: {},
    successAction: false,
    loadButtonSubmit: false,
    AuthService: new AuthService("/auth"),
  }),

  methods: {
    async handleSubmitData() {
      try {
        this.errors = {};

        this.loadButtonSubmit = true;

        await this.AuthService.requestNewPassword(
          "password/request-new-password",
          this.data
        );

        this.successAction = true;

        ToastService("E-mail enviado com sucesso!", "success");
      } catch (exception) {
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loadButtonSubmit = false;
      }
    },
  },
};
</script>

<style scoped>
.toolbar-title {
  margin-top: 25px;
  color: #0b6775;
  font-weight: bold;
  text-align: center;
  width: 100% !important;
}

.icon {
  font-size: 100px;
}
</style>